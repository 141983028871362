import { EventEmitter } from "events"; // eslint-disable-line n/prefer-node-protocol
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";
import { BackgroundFetch } from "@transistorsoft/capacitor-background-fetch";
// plugins
import { NativeLogin } from "../plugins/NativeLogin";
import { FireBaseHelper } from "../plugins/FireBaseHelperPlugin";
// services
import Constants from "./constants";
import { getServerOption } from "./serverOption";
import { isValidOAuthState, createAndStoreOAuthState, removeOAuthState, clearExpiredOAuthState } from "./oAuthState";
import Glient from "./glient";
import RocTable from "./roc-table";
import Gateways from "./gateways";
import Gateway from "./gateway";
import OauthIntegrations from "./oauthIntegrations";
import { clearGatewayPreferences } from "./gatewayPreferences";
import { initTuya/*, logoutTuya*/ } from "./tuya";
import { StorageKeys, Storage } from "./storage";
import { checkGeofencesGatewayInfo } from "./geofenceHelper";
import { stopAccessTokenAutoRefresh, restartAccessTokenAutoRefresh } from "./accessTokenAutoRefresh";
import { mergeDeep, isRocBuildUrl, getRefererUrl } from "./utils";
// types
import type { ReadonlyDeep } from "type-fest";
import type { UserId, Status } from "../types/user";
import type { /*MsgData,*/ MsgDataWithPayload } from "../types/roc-ws";
import type {
	CmdUserInfo,
	LoginChannelInfo, LoginGateway, LoginUser,
	MsgResponseLogin, UserInfoData,
} from "../types/message";
import type { Country, LoginOptions } from "../types/misc";

/**
 * Fetches and stores the user info in memory.
 * Fires changed event in case of user is logged in or out.
 *
 * @event changed
 * @event ready
 * @class User
 * @extends EventEmitter
 */
class User extends EventEmitter {

	#ready = false;
	#status: Status = "init"; // eslint-disable-line no-unused-private-class-members
	#error: Error | null = null; // eslint-disable-line no-unused-private-class-members
	#user: ReadonlyDeep<LoginUser> | undefined = undefined;
	#userId: UserId | undefined = undefined;
	#channelInfo: ReadonlyDeep<LoginChannelInfo> | undefined = undefined;
	#gateways: Array<LoginGateway> = [];
	#userInfo: ReadonlyDeep<UserInfoData> | undefined = undefined;
	#loggedInAt: number | null = null;

	constructor() {
		super();

		this.handleGlientReady = this.handleGlientReady.bind(this);
		this.handleGlientDisconnected = this.handleGlientDisconnected.bind(this);
		this.handleGlientLoginFailed = this.handleGlientLoginFailed.bind(this);

		Glient.on("ready", this.handleGlientReady);
		Glient.on("disconnected", this.handleGlientDisconnected);
		Glient.on("loginFailed", this.handleGlientLoginFailed);
	}

	public get ready() {
		return this.#ready;
	}

	public get user() {
		return this.#user;
	}

	public get userId() {
		return this.#userId;
	}

	public get channelInfo() {
		return this.#channelInfo;
	}

	public get userInfo() {
		return this.#userInfo;
	}

	public get loggedInAt() {
		return this.#loggedInAt;
	}

	public get userData() {
		return {
			ready: this.#ready,
			userId: this.#userId,
			user: this.#user,
			channelInfo: this.#channelInfo,
			userInfo: this.#userInfo,
		} as const;
	}

	async #loadRocIdTable(): Promise<void> {
		return new Promise((resolve, reject) => {
			RocTable.getRocIdTable((rocIdTable) => {
				if (rocIdTable.length === 0) {
					reject(new Error("RocIdTable has no entries."));
				} else {
					resolve();
				}
			});
		});
	}

	async #fetchUserInfo(): Promise<void> {
		return new Promise((resolve, reject) => {
			const cmd = {
				action: "userInfo",
			} as const satisfies CmdUserInfo;
			Glient.send(cmd, (error, msg) => {
				this.#error = error;
				if (!error && msg?.payload.status === "ok") {
					this.#userInfo = msg.payload.data;
					resolve();
				} else {
					this.#userInfo = undefined;
					reject(error);
				}
			});
		});
	}

	private async handleGlientReady(msg: MsgResponseLogin): Promise<void> {
		// const changed = this.#userId !== msg.payload.data.user.sub;
		restartAccessTokenAutoRefresh(msg.payload.data.token_exp * 1000);
		this.#error = null;
		this.#user = msg.payload.data.user;
		this.#userId = this.#user.sub;
		this.#channelInfo = User.#updateChannelInfo(msg.payload.data.channelInfo, this.#user.country);
		this.#gateways = msg.payload.data.gateways as Array<LoginGateway>;
		RocTable.setTableVersions(msg.payload.data.tableVersions);

		const tasks = [
			Gateways.fetchGateways(this.#gateways),
			OauthIntegrations.fetch(),
			this.#loadRocIdTable(),
			this.#fetchUserInfo(),
		];
		// TODO: check, why it sometimes happen, that on pause and onresume are called, after app comes from background
		// if (changed) {
		//	 tasks.push(this.#fetchUserInfo());
		// }

		try {
			await Promise.all(tasks);
		} catch (error) {
			console.warn("Fetch resources failed:", error);
		} finally {
			Gateway.setInitGateway(); // TODO
			this.#status = "connected";
			this.#loggedInAt = Date.now();
			if (Capacitor.isNativePlatform()) {
				void checkGeofencesGatewayInfo(Gateways.getGateways()); // long running task -> no await
				await initTuya(this.#userId);
			}
			this.#ready = true;
			this.emit("ready");
			this.emit("changed", this.userData);
		}
	}

	private handleGlientDisconnected() {
		this.#status = "disconnected";
		// TODO
		// this.emit("changed", this.getUserData());
	}

	private async handleGlientLoginFailed(msgData: MsgDataWithPayload): Promise<void> {
		// TODO: needed?
		await this.logoutCleanup(msgData.payload.data ?? "Login failed, unknown reason");
	}

	static #updateChannelInfo(channelInfo: ReadonlyDeep<LoginChannelInfo>, country: Country): ReadonlyDeep<LoginChannelInfo> {
		if (channelInfo.countries && country in channelInfo.countries) {
			return mergeDeep(channelInfo, channelInfo.countries[country]);
		}
		return channelInfo;
	}

	async #setFireBaseConfig() {
		// change FireBaseConfig for PushNotifications
		try {
			const selectedBackendServer = Storage.get(StorageKeys.selectedBackendServer);
			await FireBaseHelper.changeConfig({ environment: selectedBackendServer });
		} catch (error) {
			console.error("firebase init failed", error);
			throw error;
		}
	}

	public async login(options: LoginOptions): Promise<void> {
		// init firebase with correct config on login
		if (Capacitor.isNativePlatform()) {
			try {
				await this.#setFireBaseConfig();
			} catch (error) {
				console.error("error init firebase", error);
			}
		}

		const { channel, redirectUrl: serverRedirectUrl } = getServerOption();

		if (!globalThis.location.hash.startsWith("#/preLogin/")) {
			Storage.set(StorageKeys.launchUrl, globalThis.location.href);
		}

		const state = createAndStoreOAuthState();

		const params = new URLSearchParams({
			state: state,
			channel: channel,
			type: "li",
		});
		if (globalThis.location.hash.startsWith("#/invited/") || globalThis.location.hash.includes("invited")) {
			params.set("invited", "true");
		}
		if (isRocBuildUrl()) {
			params.set("referer", globalThis.btoa(getRefererUrl()));
		}

		try {
			const { redirectUrl } = await NativeLogin.login({
				url: `${serverRedirectUrl}?${params.toString()}`,
				backText: options.backText,
			});

			if (Capacitor.isNativePlatform() && redirectUrl) {
				// Needs to happen, because native opens second webview, which cant redirect main webview. TODO Change for Events
				globalThis.location.href = redirectUrl; // eslint-disable-line require-atomic-updates
			}
		} catch (error) {
			console.error("NativeLogin.login()", error);
			throw error;
		}
	}

	public async logout(): Promise<void> {
		await this.logoutCleanup();
		await this.#logout();
	}

	public async logoutCleanup(errorMessage: string | undefined = undefined): Promise<void> {
		this.#ready = false;
		if (Capacitor.isNativePlatform()) {
			// await logoutTuya(); Logout service fails. Issue resolved in Tuya PR
			const { enabled } = await BackgroundGeolocation.getState();
			if (enabled) {
				await BackgroundGeolocation.removeGeofences();
				await BackgroundGeolocation.stop();
			}
			this.emit("unregisterPushNotifications");
		}

		await Glient.disconnect();

		stopAccessTokenAutoRefresh();

		this.#status = (errorMessage === undefined) ? "init" : "failed";
		this.#error = (errorMessage === undefined) ? new Error(errorMessage) : null;
		this.#user = undefined;
		this.#userId = undefined;
		this.#channelInfo = undefined;
		this.#gateways = [];
		this.#userInfo = undefined;
		this.#loggedInAt = null;
		this.emit("changed", this.userData);

		await this.#cleanupStorage();
	}

	async #cleanupStorage(): Promise<void> {
		if (Capacitor.isNativePlatform()) {
			await Preferences.remove({ key: "geofence_accuracy_level" });
		}

		Storage.logoutCleanup();
	}

	async #logout() {
		const { channel, revokeUrl } = getServerOption();

		const state = createAndStoreOAuthState();

		const params = new URLSearchParams({
			state: state,
			channel: channel,
			type: "lo",
		});
		if (isRocBuildUrl()) {
			params.set("referer", globalThis.btoa(getRefererUrl()));
		}

		try {
			await NativeLogin.logout({
				url: `${revokeUrl}?${params.toString()}`,
			});

			Storage.remove(StorageKeys.launchUrl);
			if (Capacitor.isNativePlatform()) {
				if (channel === Constants.Channel.Hornbach) { // TODO: remove "hornbach" check if other themes also have smart widget
					await clearGatewayPreferences();
				}
				if (Capacitor.getPlatform() === Constants.Platform.iOS) {
					await BackgroundFetch.stop();
				}
				// TODO: use redirectUrl from logout
				const newUrl = new URL(globalThis.location.href);
				newUrl.search = "";
				newUrl.searchParams.set("state", state);
				newUrl.hash = "";
				globalThis.location.href = newUrl.href;
			}
		} catch (error) {
			console.error("NativeLogin.logout() failed", error);
		}
	}

	public async manageSearchParams(): Promise<void> {
		const urlSearchParams = new URLSearchParams(globalThis.location.search);
		const state = urlSearchParams.get("state");
		if (state) {
			if (isValidOAuthState(state)) {
				if (urlSearchParams.has("subject") && urlSearchParams.has("displayName") && urlSearchParams.has("expires")) {
					// login
					// restartAccessTokenAutoRefresh(Number(urlSearchParams.get("expires")));
				} else {
					// logout
					await this.logoutCleanup();
				}
				removeOAuthState();
			} else {
				console.warn("unknown oauth-state", state);
			}
		}
		clearExpiredOAuthState();
	}

}

export default (new User());
