import { EventEmitter } from "events"; // eslint-disable-line n/prefer-node-protocol
// services
import Glient from "./glient";
import User from "./user";
import Gateways from "./gateways";
import Gateway from "./gateway";
import Constants from "./constants";
import ClusterConstants from "./cluster-constants";
import { Storage, StorageKeys } from "./storage";
import { sortAlphabetically } from "./l10n";
// types
import type { ReadonlyDeep, WritableDeep } from "type-fest";
import type { GatewayId, GatewayStatus } from "../types/gateway";
import type { SceneId, Scenes as ScenesT, Scene } from "../types/scenes";
import type { CmdGatewayActionScenesGetAll, MsgBroadcastAttributeReport, PayloadResponseGatewayActionGatewayGetScenes } from "../types/message";
import type { IsoTimeStamp } from "../types/misc";

type ScenesCallback = (errors: Array<Error> | null, scenes: ScenesT) => void;

/**
 * A in-memory storage for scenes of a gateway. Once the scenes are loaded,
 * they are updated as soon as the "attributeReport" broadcasts are received.
 * So, the scenes are loaded and kept in memory and updated on broadcasts.
 * That's how we have the latest state of a scene.
 * @see message-handler.js
 * @class Scenes
 * @extends EventEmitter
 */
class Scenes extends EventEmitter {

	#loaded = false;
	#fetchedAt = 0;
	#gatewayId: GatewayId | null = null;
	#scenes: WritableDeep<ScenesT> = [];

	constructor() {
		super();

		this.setMaxListeners(256); // a listener per scene in the scenes view

		this.handleSelectedGatewayChanged = this.handleSelectedGatewayChanged.bind(this);
		this.handleGatewayStatusChanged = this.handleGatewayStatusChanged.bind(this);

		Gateway.on("selectedGatewayChanged", this.handleSelectedGatewayChanged);
		Gateway.on("statusChanged", this.handleGatewayStatusChanged);
	}

	/**
	 * On gateway changed, scenes are fetched for the selected gateway.
	 */
	private handleSelectedGatewayChanged(/*gateway*/): void {
		this.#loaded = false;
		// this.#fetchScenes(() => {});
	}

	private handleGatewayStatusChanged(status: GatewayStatus | undefined): void {
		if (status === Constants.Gateway.Status.Unreachable) {
			this.#loaded = false;
			this.#fetchedAt = 0;
			this.#scenes = [];
			this.emit("reset");
			this.emit("scenesChanged", []);
		}
	}

	public isLoaded(): boolean {
		return this.#loaded;
	}

	/**
	 * @returns {Object[]} List of scenes.
	 */
	public get(): ScenesT {
		return [...this.#scenes];
	}

	public getSceneById(sceneId: SceneId): Scene | undefined {
		return this.#scenes.find((scene) => (scene.id === sceneId));
	}

	static #sortFunc(sceneA: Scene, sceneB: Scene): number {
		return sortAlphabetically(sceneA.name, sceneB.name);
	}

	/**
	 * Fetches the scenes from server and assign the category.
	 * @param {Function} callback
	 */
	public getScenes(callback: ScenesCallback): void {
		if (Gateways.loaded) {
			this.#fetchScenes(callback);
		} else {
			Gateways.once("gatewaysChanged", () => {
				this.#fetchScenes(callback);
			});
		}
	}

	/**
	 * Fetches the scenes from server.
	 * TODO: make async
	 * @param {Function} callback
	 */
	#fetchScenes(callback: ScenesCallback): void {
		const selectedGatewayId = Gateway.selectedGatewayId;
		if (selectedGatewayId === undefined || Gateway.getMessage() !== null) {
			const errors = [new Error("No Gateway selected or unreachable")];
			callback(errors, [...this.#scenes]);
		} else if (this.#loaded && this.#gatewayId === selectedGatewayId && User.loggedInAt <= this.#fetchedAt) {
			callback(null, [...this.#scenes]);
		} else {
			const gatewayIds = Gateways.getGatewayIdsOffVisibleDevices();
			const tasks = gatewayIds.map((gatewayId) => (
				new Promise<ScenesT>((resolve, reject) => {
					const cmd = {
						action: "gatewayAction",
						module: "scenes",
						function: "get_all",
						params: [],
						gatewayId: gatewayId,
					} as const satisfies CmdGatewayActionScenesGetAll;
					Glient.send(cmd, (error, msg) => {
						if (!error && msg?.payload.status === "ok") {
							const payload = msg.payload as ReadonlyDeep<PayloadResponseGatewayActionGatewayGetScenes>; // TODO
							const scenes = payload.data as ScenesT;
							resolve(scenes);
						} else {
							reject(error);
						}
					});
				})
			));

			void Promise.allSettled(tasks).then((results) => {
				const errors = results.filter((result) => (result.status === "rejected")).map((result) => (result.reason));
				if (errors.length > 0) {
					console.warn("Fetch devices failed:", errors);
				}
				this.#scenes = results
					.filter((result) => (result.status === "fulfilled"))
					.flatMap((result) => (result.value))
					.toSorted(Scenes.#sortFunc);
				this.#loaded = true;
				this.#fetchedAt = Date.now();
				this.#gatewayId = selectedGatewayId;

				callback((errors.length === 0) ? null : errors, [...this.#scenes]);
			});
		}
	}

	/**
	 * Updates the scene when a broadcast is received.
	 * @param {Object} msg
	 * @see message-handler.js
	 */
	public handleAttributeReportBroadcast(msg: MsgBroadcastAttributeReport): void {
		const sceneAddObject = msg.payload._childDocuments_.find((childDocument) => (childDocument.id === ClusterConstants.BC0003.Attributes.Created));
		const sceneEditObject = msg.payload._childDocuments_.find((childDocument) => (childDocument.id === ClusterConstants.BC0003.Attributes.Edited));
		const currentSceneObject = sceneAddObject ?? sceneEditObject;
		if (currentSceneObject) {
			const sceneId = currentSceneObject[currentSceneObject.val];
			const scene = this.#scenes.find((scene) => (scene.id === sceneId));
			const nameObject = msg.payload._childDocuments_.find((childDocument) => (childDocument.id === ClusterConstants.BC0003.Attributes.Name));
			const iconObject = msg.payload._childDocuments_.find((childDocument) => (childDocument.id === ClusterConstants.BC0003.Attributes.Icon));
			const actionsObject = msg.payload._childDocuments_.find((childDocument) => (childDocument.id === ClusterConstants.BC0003.Attributes.Actions));
			if (scene) {
				scene.name = nameObject ? nameObject[nameObject.val] : scene.name;
				scene.icon_id = iconObject ? iconObject[iconObject.val] : scene.icon_id;
				scene.actions = actionsObject ? actionsObject[actionsObject.val] : scene.actions;
				this.emit("sceneUpdated", scene);
			} else {
				const isoTimestamp = new Date().toISOString() as IsoTimeStamp;
				const _scene = {
					id: sceneId,
					name: nameObject ? nameObject[nameObject.val] : "",
					icon_id: iconObject ? iconObject[iconObject.val] : "",
					created: isoTimestamp,
					updated: isoTimestamp,
					actions: actionsObject ? actionsObject[actionsObject.val] : [],
				} as const satisfies Scene;
				this.#scenes = [...this.#scenes, _scene].toSorted(Scenes.#sortFunc);
				this.emit("sceneAdded", _scene);
			}
			this.emit("scenesChanged", [...this.#scenes]);
		}

		const sceneRemoveObject = msg.payload._childDocuments_.find((childDocument) => (childDocument.id === ClusterConstants.BC0003.Attributes.Deleted));
		if (sceneRemoveObject) {
			const scene = this.deleteScene(sceneRemoveObject[sceneRemoveObject.val]);
			const gateway = Gateway.selectedGateway;
			if (scene && gateway) {
				const storedList = Storage.get(StorageKeys.sceneIds, {GWID: gateway.id}, {GWID: gateway.srcGw});
				if (storedList.length > 0) {
					const scenesOrder = storedList.filter((sceneId) => (sceneId !== scene.id));
					Storage.set(StorageKeys.sceneIds, scenesOrder, {GWID: gateway.id});
				}
				this.emit("sceneDeleted", scene);
			}
			this.emit("scenesChanged", [...this.#scenes]);
		}
	}

	/**
	 * Deletes a scene.
	 * @param {String} sceneId
	 * @returns {Object} deleted scene.
	 */
	public deleteScene(sceneId: SceneId): Scene | null {
		if (this.#scenes.length > 0) {
			const index = this.#scenes.findIndex((scene) => (scene.id === sceneId));
			if (index > -1) {
				const scene = this.#scenes[index]!;
				this.#scenes.splice(index, 1); // TODO: .toSpliced()
				return scene;
			}
		}
		return null;
	}

}

export default (new Scenes());
